import { useEffect, useState, useMemo } from "react";
import * as toastr from "toastr";
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "src/containers/DashboardNavbar";
import SoftBox from "src/components/SoftBox";
import { Card, Checkbox, Grid, Icon, Modal, Stack } from "@mui/material";
import SoftTypography from "src/components/SoftTypography";
import SoftButton from "src/components/SoftButton";
import DataTable from "src/components/Tables/DataTable";
import Footer from "src/components/Footer";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import { useCollection } from "react-firebase-hooks/firestore";
import {
  getUserPermissionsCollection,
  getUserPermissionsCollectionGroup,
} from "src/features/user/collections";
import { getStatusCollection } from "src/features/status/collections";
import { getInvitesCollection } from "src/features/invites/collections";
import { useClubs } from "src/features/club/ClubProvider";
import DualView from "src/components/DualView/DualView";
import MemberInfo from "./MemberInfo";
import { format } from "date-fns";
import { AddNewUserModal } from "src/modals/AddNewUserModal";
import { query, where, deleteDoc, doc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import Swal from "sweetalert2";
import { uniqBy } from "lodash-es";
import NameCell from "./components/NameCell";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { systemPermissions } from "src/interfaces/roles/role.interface";
import { usePermissions } from "src/hooks/usePermissions";
import { WithPermissions } from "src/components/WithPermissions/WithPermissions";
import { CSVLink } from "react-csv";

const userColumns = () => [
  {
    Header: "name",
    accessor: "displayName",
    width: "20%",
  },
  {
    Header: "email",
    accessor: "email",
  },
  {
    Header: "phone",
    accessor: "phoneNumber",
  },
  {
    Header: "date added",
    accessor: "addedAt",
    width: "8%",
  },
];

const userColumnsAdmin = (
  handleDeleteClick,
  sendInvite,
  setSelectedMembers,
  selectedMembers,
  handleRestoreClick
) => [
  {
    Header: "name",
    accessor: "displayName",
    width: "20%",
    Cell: ({ row: { original } }) => {
      return (
        <SoftBox
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <NameCell
            checked={selectedMembers.indexOf(original.id) >= 0}
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedMembers([...selectedMembers, original.id]);
                return;
              } else {
                const newSelectedMembers = selectedMembers.filter(
                  (member) => member !== original.id
                );
                setSelectedMembers(newSelectedMembers);
              }
            }}
          >
            {original.displayName}
          </NameCell>
        </SoftBox>
      );
    },
  },
  {
    Header: "Roles",
    accessor: "userRoles",
  },
  {
    Header: "email",
    accessor: "email",
  },
  {
    Header: "phone",
    accessor: "phoneNumber",
  },
  {
    Header: "date added",
    accessor: "addedAt",
    width: "14%",
  },
  {
    Header: "Actions",
    width: "10%",
    Cell: ({ row: { original } }) => (
      <SoftBox display="flex" alignItems="center">
        {!original?.deleted ? (
          <>
            <SoftButton
              color="error"
              size="medium"
              variant="text"
              onClick={(e) => handleDeleteClick(e, original)}
              style={{ padding: 0, minWidth: "25px" }}
            >
              <Icon className="material-icons-round">delete</Icon>
            </SoftButton>
            {original.status?.state === "Not Invited" && (
              <SoftButton
                color="success"
                size="medium"
                variant="text"
                onClick={(e) => sendInvite(e, original)}
                style={{ padding: 0, minWidth: "25px" }}
              >
                <Icon className="material-icons-round">send</Icon>
              </SoftButton>
            )}
            {original.status?.state === "Invite Sent" && (
              <SoftButton
                color="warning"
                size="medium"
                variant="text"
                onClick={(e) => sendInvite(e, original)}
                style={{ padding: 0, minWidth: "25px" }}
              >
                <Icon className="material-icons-round">replay</Icon>
              </SoftButton>
            )}
          </>
        ) : (
          <SoftButton
            color="info"
            size="medium"
            variant="text"
            onClick={(e) => handleRestoreClick(e, original)}
            style={{ padding: 0, minWidth: "25px" }}
          >
            <Icon className="material-icons-round">person_add</Icon>
          </SoftButton>
        )}
      </SoftBox>
    ),
  },
];

function ManageMembers() {
  const [showPanel, setShowPanel] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const {
    clubUserRoles,
    selectedLocationId,
    selectedLocation,
    clubUsers,
    selectedClubId,
    selectedClub,
  } = useClubs();
  const { hasAccess } = usePermissions();
  const { updateData } = entityCrudUtils();
  const [usersWithState, setUsersWithState] = useState([]);
  const [usersInvited, setUsersInvited] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [locationUsers, setLocationUsers] = useState([]);
  const [openAddNewUserModal, setOpenAddNewUsermodal] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [showDeleted, setShowDeleted] = useState(false);
  const [bulkActionsMenu, setBulkActionsMenu] = useState(null);
  const [csvData, setCsvData] = useState(null);

  const clubRoles = useMemo(() => {
    const clubUserRolesMap = new Map(
      clubUserRoles.map((obj) => [obj.id, { ...obj }])
    );
    return clubUserRolesMap;
  }, [clubUserRoles]);

  const toggleShowDeleted = () => setShowDeleted(!showDeleted);

  const handleOpenAddNewUserModal = () => {
    setOpenAddNewUsermodal(true);
  };

  const handleCloseAddNewUserModal = () => {
    setOpenAddNewUsermodal(false);
  };

  const [userPermissionsSnapshot, loadingUserPermissions] = useCollection(
    query(
      getUserPermissionsCollectionGroup(),
      where("locationId", "==", selectedLocationId)
    ),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    }
  );

  const [invitesSnapshot, loadingInvites] = useCollection(
    query(getInvitesCollection(), where("clubId", "==", selectedClubId)),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    }
  );

  const [statusSnapshot, loadingStatus] = useCollection(getStatusCollection(), {
    snapshotListenOptions: {
      includeMetadataChanges: true,
    },
  });

  const getUsersFromPermissions = async (usersPermissions) => {
    const users = [];

    const uniqueUsersPermissions = uniqBy(
      usersPermissions,
      (permission) => permission.ref.parent.parent.id
    );
    uniqueUsersPermissions.forEach((document) => {
      if (document?.data()?.deleted && !showDeleted) return;
      // if (document?.data()?.inactive) return;

      const path = document.ref.parent.parent;
      const userData = clubUsers.get(path.id);
      if (!userData) return;

      let userRoles = [];
      if (
        document.data()?.userRoles &&
        Array.isArray(document.data()?.userRoles)
      ) {
        userRoles = document.data()?.userRoles?.map((role) => {
          return clubRoles.get(role)?.label ?? "";
        });
      }

      users.push({
        ...userData,
        id: document.ref.parent.parent.id,
        userRoles: userRoles?.join(", "),
        invited: document.data().invited,
        deleted: document.data().deleted,
        inactive: document.data().inactive || false,
        addedAt:
          document.data()?.addedAt &&
          format(document.data().addedAt?.toDate(), "MMM dd yyyy"),
      });
    });

    setLocationUsers(users);
  };

  useEffect(() => {
    if (userPermissionsSnapshot && !loadingUserPermissions) {
      const locationUsersPermissions = [];
      userPermissionsSnapshot.docs.forEach((userPermission) => {
        // const userPermissionData = userPermission.data();
        // if (userPermissionData.deleted) return;
        locationUsersPermissions.push(userPermission);
      });
      getUsersFromPermissions(locationUsersPermissions);
    }
  }, [
    userPermissionsSnapshot,
    loadingUserPermissions,
    showDeleted,
    clubUsers,
    selectedLocationId,
    clubRoles,
  ]);

  const addUserStatus = async (statuses) => {
    const updatedLocationUsers = await Promise.all(
      locationUsers.map(async (user) => {
        const statusIndex = statuses.findIndex(
          (status) => user.id === status.id
        );
        const inviteIndex = usersInvited.findIndex(
          (invite) => user.id === invite.id
        );
        if (statusIndex >= 0) user.status = statuses[statusIndex];
        else if (inviteIndex >= 0) user.status = { state: "Invite Sent" };
        else if (user.invited === false) user.status = { state: "Not Invited" };
        return user;
      })
    );
    setUsersWithState(updatedLocationUsers);
  };

  useEffect(() => {
    if (loadingStatus || loadingUserPermissions) return;

    const statusList = [];
    if (statusSnapshot) {
      statusSnapshot.docs.map((document) => {
        statusList.push({ id: document.id, ...document.data() });
      });
    }
    addUserStatus(statusList);
  }, [locationUsers, usersInvited, statusSnapshot, loadingUserPermissions]);

  useEffect(() => {
    if (loadingInvites) return;
    const invitesList = [];
    if (invitesSnapshot?.docs) {
      invitesSnapshot.docs.map((document) => {
        const inviteData = document.data();
        invitesList.push({
          id: document.id,
          displayName: `${inviteData.firstName} ${inviteData.lastName}`,
          status: { state: "Pending" },
          invite: true,
          ...inviteData,
        });
      });
    }
    setUsersInvited(invitesList);
  }, [invitesSnapshot, loadingInvites]);

  const handlePanelClose = () => {
    setShowPanel(false);
    setSelectedMember(null);
  };
  const handleRowSelection = (member) => {
    if (member.invite) {
      handlePanelClose();
      return;
    }
    if (hasAccess(systemPermissions.VIEW_MEMBER_DETAILS)) {
      setSelectedMember(member);
      setShowPanel(true);
    } else {
      setShowPanel(false);
    }
  };

  const deleteInvite = async (member) => {
    const newSwal = Swal.mixin({
      customClass: {
        cancelButton: "button",
        confirmButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Delete Invite?",
        text: "If the user tries to accept the invite, they will be notified that the invite has been deleted.",
        showCancelButton: true,
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.value) {
          try {
            await deleteDoc(doc(getInvitesCollection(), member.id));
            toastr.success("Invite deleted successfully");
          } catch (error) {
            toastr.error("Error deleting invite");
          }
        }
      });
  };

  const deleteUser = async (member) => {
    const newSwal = Swal.mixin({
      customClass: {
        cancelButton: "button",
        confirmButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Delete User?",
        text: "User data will remain, but the user will no longer have access to this club/school.",
        showCancelButton: true,
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.value) {
          try {
            // await deleteData({
            //   entity: getUserPermissionsCollection(member.id),
            //   pathSegmentsArr: [selectedLocationId],
            // });
            await updateData(
              {
                entity: getUserPermissionsCollection(member.id),
                pathSegmentsArr: [selectedLocationId],
              },
              { deleted: true, hide: true }
            );
            await deleteDoc(doc(getInvitesCollection(), member.id));
            // usersWithState.splice(usersWithState.indexOf(member), 1);
            toastr.success("User deleted successfully");
          } catch (error) {
            toastr.error("Error deleting invite");
          }
        }
      });
  };
  const restoreUser = async (member) => {
    const newSwal = Swal.mixin({
      customClass: {
        cancelButton: "button",
        confirmButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Restore User?",
        text: "User data will be restored as it previously was.",
        showCancelButton: true,
        confirmButtonText: "Restore",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.value) {
          try {
            await updateData(
              {
                entity: getUserPermissionsCollection(member.id),
                pathSegmentsArr: [selectedLocationId],
              },
              { deleted: false, hide: false }
            );
            await deleteDoc(doc(getInvitesCollection(), member.id));
            toastr.success("User restored");
          } catch (error) {
            toastr.error("Error restoring user");
          }
        }
      });
  };

  const handleDeleteClick = (e, member) => {
    if (e) e.stopPropagation();
    if (member.invite) {
      deleteInvite(member);
    } else {
      deleteUser(member);
    }
  };

  const handleRestoreClick = (e, member) => {
    if (e) e.stopPropagation();
    restoreUser(member);
  };

  const handleSendInvite = (e, member) => {
    if (e) e.stopPropagation();
    const newSwal = Swal.mixin({
      customClass: {
        cancelButton: "button button-error",
        confirmButton: "button",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Send Invite?",
        text: "This will send an invite to the user to join this club/school.",
        showCancelButton: true,
        confirmButtonText: "Send",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.value) {
          try {
            const functions = getFunctions();
            const resendInvite = httpsCallable(functions, "resendInvite");
            resendInvite({
              clubId: selectedClubId,
              locationId: selectedLocationId,
              inviteId: member.id,
              clubName: selectedClub.name,
            })
              .then((resendInviteResult) => {
                console.debug("Resend Invite Result:", resendInviteResult);
                toastr.success("Invite sent successfully");
              })
              .catch((error) => {
                console.error("Add User Error:", error);
                toastr.error("Error sending invite");
              });
          } catch (error) {
            toastr.error("Error sending invite");
          }
        }
      });
  };

  const openMenu = (event) => setBulkActionsMenu(event.currentTarget);
  const closeMenu = async (filter) => {
    switch (filter) {
      case "resendInvite":
        for (const member of selectedMembers) {
          const functions = getFunctions();
          const resendInvite = httpsCallable(functions, "resendInvite");
          resendInvite({
            clubId: selectedClubId,
            locationId: selectedLocationId,
            inviteId: member,
            clubName: selectedClub.name,
          });
        }
        break;
      case "delete":
        for (const member of selectedMembers) {
          await updateData(
            {
              entity: getUserPermissionsCollection(member),
              pathSegmentsArr: [selectedLocationId],
            },
            { deleted: true }
          );
        }
        break;
      default:
        break;
    }
    setSelectedMembers([]);
    setBulkActionsMenu(null);
  };

  useEffect(() => {
    updateCsvData(tableRows);
  }, [tableRows]);

  const updateCsvData = (rows) => {
    const csvDataFromRows = rows.map((row) => ({
      ID: row.id,
      "Full Name": row.displayName,
      "First Name": row.firstName,
      "Last Name": row.lastName,
      Email: row.email,
      Phone: row.phoneNumber,
      "Date Added": row.addedAt,
      "Badge Number": row.rfid,
    }));

    setCsvData(csvDataFromRows);
  };

  const renderMenu = (
    <Menu
      anchorEl={bulkActionsMenu}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={Boolean(bulkActionsMenu)}
      onClose={() => closeMenu()}
      keepMounted
    >
      <MenuItem onClick={() => closeMenu("resendInvite")}>
        Send Invite ({selectedMembers.length})
      </MenuItem>
      <Divider
        sx={{
          margin: "0.5rem 0",
        }}
      />
      <MenuItem onClick={() => closeMenu("delete")}>
        <SoftTypography variant="button" color="error" fontWeight="regular">
          Set Inactive ({selectedMembers.length})
        </SoftTypography>
      </MenuItem>
    </Menu>
  );

  const tableColumns = useMemo(
    () =>
      hasAccess(systemPermissions.EDIT_MEMBERS)
        ? userColumnsAdmin(
            handleDeleteClick,
            handleSendInvite,
            setSelectedMembers,
            selectedMembers,
            handleRestoreClick
          )
        : userColumns(),
    [usersWithState, selectedMembers, selectedLocationId]
  );

  useEffect(() => {
    setTableRows(usersWithState);
  }, [usersWithState]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <Card>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            p={3}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <SoftBox lineHeight={1}>
                  <SoftTypography variant="h5" fontWeight="medium">
                    Members
                  </SoftTypography>
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Manage all members here.
                  </SoftTypography>
                </SoftBox>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Stack spacing={1} direction="row" justifyContent="flex-end">
                  {selectedMembers.length > 0 && (
                    <SoftButton
                      variant="contained"
                      color="error"
                      onClick={openMenu}
                      style={{ marginRight: "15px" }}
                    >
                      Bulk Actions&nbsp;
                      <Icon>keyboard_arrow_down</Icon>
                    </SoftButton>
                  )}
                  {renderMenu}
                  <SoftBox display="flex" alignItems="center" pr={3}>
                    {selectedMembers.length > 0 ? (
                      <SoftButton
                        variant="text"
                        color="info"
                        size="small"
                        onClick={() => setSelectedMembers([])}
                      >
                        Deselect All
                      </SoftButton>
                    ) : (
                      <SoftButton
                        variant="text"
                        color="info"
                        size="small"
                        onClick={() =>
                          setSelectedMembers(tableRows.map((row) => row.id))
                        }
                      >
                        Select All
                      </SoftButton>
                    )}
                  </SoftBox>
                  <WithPermissions
                    permissions={[systemPermissions.VIEW_INACTIVE_MEMBERS]}
                  >
                    <SoftBox display="flex" alignItems="center" pr={3}>
                      <Checkbox
                        checked={showDeleted}
                        onChange={toggleShowDeleted}
                      />
                      <SoftTypography variant="button" fontWeight="medium">
                        Show Inactive
                      </SoftTypography>
                    </SoftBox>
                  </WithPermissions>
                  <WithPermissions permissions={systemPermissions.ADD_MEMBERS}>
                    <SoftButton
                      variant="gradient"
                      color="info"
                      size="small"
                      onClick={() => handleOpenAddNewUserModal()}
                    >
                      + new member
                    </SoftButton>
                  </WithPermissions>
                  <WithPermissions
                    permissions={systemPermissions.EXPORT_MEMBERS}
                  >
                    {csvData && (
                      <SoftBox ml={1}>
                        <SoftBox>
                          <CSVLink
                            data={csvData}
                            filename={`${selectedClub.name}-${
                              selectedLocation?.icao
                            }-members-${format(new Date(), "MM/dd/yyyy")}.csv`}
                            target="_blank"
                          >
                            <SoftButton variant="outlined" color="dark">
                              <Icon>description</Icon>
                              &nbsp;export csv
                            </SoftButton>
                          </CSVLink>
                        </SoftBox>
                      </SoftBox>
                    )}
                  </WithPermissions>
                  {/* <SoftButton variant="outlined" color="info" size="small">
                import
              </SoftButton>
              <SoftButton variant="outlined" color="info" size="small">
                export
              </SoftButton> */}
                </Stack>
              </Grid>
            </Grid>
          </SoftBox>
          <DualView
            onClosePanel={handlePanelClose}
            showPanel={showPanel}
            rightMinWidth={500}
          >
            {(usersWithState.length > 0 || usersInvited.length > 0) && (
              <DataTable
                table={{
                  columns: tableColumns,
                  rows: tableRows,
                }}
                entriesPerPage={{
                  defaultValue: 30,
                  entries: [15, 30, 50, 100],
                }}
                onRowSelect={handleRowSelection}
                canSearch
                selectedRowId={selectedMember?.id}
              />
            )}
            <WithPermissions
              permissions={systemPermissions.VIEW_MEMBER_DETAILS}
            >
              {useMemo(
                () => (
                  <MemberInfo member={selectedMember} />
                ),
                [selectedMember]
              )}
            </WithPermissions>
          </DualView>
        </Card>
      </SoftBox>
      <Footer />
      <Modal
        open={openAddNewUserModal}
        onClose={handleCloseAddNewUserModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <AddNewUserModal handleClose={handleCloseAddNewUserModal} />
      </Modal>
    </DashboardLayout>
  );
}

export default ManageMembers;
